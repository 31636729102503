/* DOMAINE */
@font-face {
	font-family: "Domaine Disp";
	src: url("assets/fonts/DomaineDisp-Bold.eot");
	src: local("DomaineDisp-Bold"),
		url("assets/fonts/DomaineDisp-Bold.eot?#iefix") format("embedded-opentype"),
		url("assets/fonts/DomaineDisp-Bold.woff2") format("woff2"),
		url("assets/fonts/DomaineDisp-Bold.woff") format("woff"),
		url("assets/fonts/DomaineDisp-Bold.ttf") format("truetype"),
		url("assets/fonts/DomaineDisp-Bold.svg#DomaineDisp-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Domaine Disp";
	src: url("assets/fonts/DomaineDisp-BoldItalic.eot");
	src: local("DomaineDisp-BoldItalic"),
		url("assets/fonts/DomaineDisp-BoldItalic.eot?#iefix")
			format("embedded-opentype"),
		url("assets/fonts/DomaineDisp-BoldItalic.woff2") format("woff2"),
		url("assets/fonts/DomaineDisp-BoldItalic.woff") format("woff"),
		url("assets/fonts/DomaineDisp-BoldItalic.ttf") format("truetype"),
		url("assets/fonts/DomaineDisp-BoldItalic.svg#DomaineDisp-BoldItalic")
			format("svg");
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Domaine Disp";
	src: url("assets/fonts/DomaineDispMedium.eot");
	src: local("DomaineDispMedium"),
		url("assets/fonts/DomaineDispMedium.eot?#iefix") format("embedded-opentype"),
		url("assets/fonts/DomaineDispMedium.woff2") format("woff2"),
		url("assets/fonts/DomaineDispMedium.woff") format("woff"),
		url("assets/fonts/DomaineDispMedium.ttf") format("truetype"),
		url("assets/fonts/DomaineDispMedium.svg#DomaineDispMedium") format("svg");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Domaine Disp";
	src: url("assets/fonts/DomaineDispMedium-Italic.eot");
	src: local("DomaineDispMedium-Italic"),
		url("assets/fonts/DomaineDispMedium-Italic.eot?#iefix")
			format("embedded-opentype"),
		url("assets/fonts/DomaineDispMedium-Italic.woff2") format("woff2"),
		url("assets/fonts/DomaineDispMedium-Italic.woff") format("woff"),
		url("assets/fonts/DomaineDispMedium-Italic.ttf") format("truetype"),
		url("assets/fonts/DomaineDispMedium-Italic.svg#DomaineDispMedium-Italic")
			format("svg");
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Domaine Disp";
	src: url("assets/fonts/DomaineDisp-Regular.eot");
	src: local("DomaineDisp-Regular"),
		url("assets/fonts/DomaineDisp-Regular.eot?#iefix")
			format("embedded-opentype"),
		url("assets/fonts/DomaineDisp-Regular.woff2") format("woff2"),
		url("assets/fonts/DomaineDisp-Regular.woff") format("woff"),
		url("assets/fonts/DomaineDisp-Regular.ttf") format("truetype"),
		url("assets/fonts/DomaineDisp-Regular.svg#DomaineDisp-Regular")
			format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Domaine Disp";
	src: url("assets/fonts/DomaineDisp-RegularItalic.eot");
	src: local("DomaineDisp-RegularItalic"),
		url("assets/fonts/DomaineDisp-RegularItalic.eot?#iefix")
			format("embedded-opentype"),
		url("assets/fonts/DomaineDisp-RegularItalic.woff2") format("woff2"),
		url("assets/fonts/DomaineDisp-RegularItalic.woff") format("woff"),
		url("assets/fonts/DomaineDisp-RegularItalic.ttf") format("truetype"),
		url("assets/fonts/DomaineDisp-RegularItalic.svg#DomaineDisp-RegularItalic")
			format("svg");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Domaine Disp";
	src: url("assets/fonts/DomaineDispSemibold.eot");
	src: local("DomaineDispSemibold"),
		url("assets/fonts/DomaineDispSemibold.eot?#iefix")
			format("embedded-opentype"),
		url("assets/fonts/DomaineDispSemibold.woff2") format("woff2"),
		url("assets/fonts/DomaineDispSemibold.woff") format("woff"),
		url("assets/fonts/DomaineDispSemibold.ttf") format("truetype"),
		url("assets/fonts/DomaineDispSemibold.svg#DomaineDispSemibold")
			format("svg");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Domaine Disp";
	src: url("assets/fonts/DomaineDispSemibold-Italic.eot");
	src: local("DomaineDispSemibold-Italic"),
		url("assets/fonts/DomaineDispSemibold-Italic.eot?#iefix")
			format("embedded-opentype"),
		url("assets/fonts/DomaineDispSemibold-Italic.woff2") format("woff2"),
		url("assets/fonts/DomaineDispSemibold-Italic.woff") format("woff"),
		url("assets/fonts/DomaineDispSemibold-Italic.ttf") format("truetype"),
		url("assets/fonts/DomaineDispSemibold-Italic.svg#DomaineDispSemibold-Italic")
			format("svg");
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

/* HELVETICA */
@font-face {
	font-family: "Helvetica";
	src: url("assets/fonts/Helvetica-BoldOblique.eot");
	src: local("Helvetica-BoldOblique"),
		url("assets/fonts/Helvetica-BoldOblique.eot?#iefix")
			format("embedded-opentype"),
		url("assets/fonts/Helvetica-BoldOblique.woff2") format("woff2"),
		url("assets/fonts/Helvetica-BoldOblique.woff") format("woff"),
		url("assets/fonts/Helvetica-BoldOblique.ttf") format("truetype"),
		url("assets/fonts/Helvetica-BoldOblique.svg#Helvetica-BoldOblique")
			format("svg");
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Helvetica";
	src: url("assets/fonts/Helvetica-Bold.eot");
	src: local("Helvetica-Bold"),
		url("assets/fonts/Helvetica-Bold.eot?#iefix") format("embedded-opentype"),
		url("assets/fonts/Helvetica-Bold.woff2") format("woff2"),
		url("assets/fonts/Helvetica-Bold.woff") format("woff"),
		url("assets/fonts/Helvetica-Bold.ttf") format("truetype"),
		url("assets/fonts/Helvetica-Bold.svg#Helvetica-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Helvetica";
	src: url("assets/fonts/Helvetica-Light.eot");
	src: local("Helvetica-Light"),
		url("assets/fonts/Helvetica-Light.eot?#iefix") format("embedded-opentype"),
		url("assets/fonts/Helvetica-Light.woff2") format("woff2"),
		url("assets/fonts/Helvetica-Light.woff") format("woff"),
		url("assets/fonts/Helvetica-Light.ttf") format("truetype"),
		url("assets/fonts/Helvetica-Light.svg#Helvetica-Light") format("svg");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Helvetica";
	src: url("assets/fonts/Helvetica-LightOblique.eot");
	src: local("Helvetica-LightOblique"),
		url("assets/fonts/Helvetica-LightOblique.eot?#iefix")
			format("embedded-opentype"),
		url("assets/fonts/Helvetica-LightOblique.woff2") format("woff2"),
		url("assets/fonts/Helvetica-LightOblique.woff") format("woff"),
		url("assets/fonts/Helvetica-LightOblique.ttf") format("truetype"),
		url("assets/fonts/Helvetica-LightOblique.svg#Helvetica-LightOblique")
			format("svg");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Helvetica";
	src: url("assets/fonts/Helvetica-Oblique.eot");
	src: local("Helvetica-Oblique"),
		url("assets/fonts/Helvetica-Oblique.eot?#iefix") format("embedded-opentype"),
		url("assets/fonts/Helvetica-Oblique.woff2") format("woff2"),
		url("assets/fonts/Helvetica-Oblique.woff") format("woff"),
		url("assets/fonts/Helvetica-Oblique.ttf") format("truetype"),
		url("assets/fonts/Helvetica-Oblique.svg#Helvetica-Oblique") format("svg");
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: "Helvetica";
	src: url("assets/fonts/Helvetica.eot");
	src: local("Helvetica"),
		url("assets/fonts/Helvetica.eot?#iefix") format("embedded-opentype"),
		url("assets/fonts/Helvetica.woff2") format("woff2"),
		url("assets/fonts/Helvetica.woff") format("woff"),
		url("assets/fonts/Helvetica.ttf") format("truetype"),
		url("assets/fonts/Helvetica.svg#Helvetica") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Margo Script";
	src: url("assets/fonts/MargoScript-Regular.eot");
	src: local("MargoScript-Regular"),
		url("assets/fonts/MargoScript-Regular.eot?#iefix")
			format("embedded-opentype"),
		url("assets/fonts/MargoScript-Regular.woff2") format("woff2"),
		url("assets/fonts/MargoScript-Regular.woff") format("woff"),
		url("assets/fonts/MargoScript-Regular.ttf") format("truetype"),
		url("assets/fonts/MargoScript-Regular.svg#MargoScript-Regular")
			format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

body {
	font-family: "Domaine Disp";
}
