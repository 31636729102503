#demographyPieChart .recharts-default-legend {
	display: grid;
	grid-template-columns: repeat(2, 110px);
	gap: 10px 10px;
	justify-items: start;
	align-items: center;
}

#demographyPieChart .recharts-legend-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

#demographyPieChart .recharts-legend-item {
	margin-right: 0;
}

#demographyPieChart .recharts-legend-item svg {
	border-radius: 3px;
	margin-right: 8px !important;
}

#demographyPieChart .recharts-legend-item-text {
	font-family: Helvetica, sans-serif;
}
